export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const REPOS_LOADING = 'REPOS_LOADING';
export const REPOS_UNLOAD = 'REPOS_UNLOAD';
export const PROFILE_UNLOAD = 'PROFILE_UNLOAD';
export const PROFILES_LOADING = 'PROFILES_LOADING';
export const PROFILES_UNLOAD = 'PROFILES_UNLOAD';
// export const LOADING = 'LOADING';
// export const UNLOAD = 'UNLOAD';
export const PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND';
export const CLEAR_CURRENT_PROFILE = 'CLEAR_CURRENT_PROFILE';
export const GET_GITHUB_REPOS = 'GET_GITHUB_REPOS';
export const POST_LOADING = 'POST_LOADING';
export const POST_UNLOAD = 'POST_UNLOAD';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const ADD_POST = 'ADD_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const COMMENT_LOADING = 'COMMENT_LOADING';
export const COMMENT_UNLOAD = 'COMMENT_UNLOAD';

