import React from 'react'

function NotFound() {
 return (
  <div>
   <h1 className="display-4">Page not found</h1>
   <p>Sorry, this page does not exist</p>
  </div>
 )
}

export default NotFound;