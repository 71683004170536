import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { deletePost, addLike, removeLike } from '../../actions/postActions';

class PostItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      postId: '',
      // userHandle: ''
    }

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onLikeClick = this.onLikeClick.bind(this);
    this.onUnlikeClick = this.onUnlikeClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      postId: this.props.post._id,
    });
  }

  onDeleteClick() {
    this.props.deletePost(this.state.postId);
  }

  onLikeClick() {
    this.props.addLike(this.state.postId);
  }

  onUnlikeClick() {
    this.props.removeLike(this.state.postId);
  }

  findUserLike(likes) {
    const { auth } = this.props;
    if (likes.filter(like => like.user === auth.user.id).length > 0) {
      return true;
    } else {
      return false;
    }
  }

  render() {

    const { post, auth, showActions } = this.props;

    return (
      <div className="card card-body mb-3">
        <div className="row">
          <div className="col-md-2">
            {/* <Link to={this.state.userHandle}> */}
            <img className="rounded-circle d-none d-md-block" src={post.avatar}
              alt="" />
            {/* </Link> */}
            <br />
            <p className="text-center">{post.name}</p>
          </div>
          <div className="col-md-10">
            <p className="lead">{post.text}</p>
            {showActions ? (
              <span>
                <button onClick={this.onLikeClick} type="button" className="btn btn-light mr-1">
                  <i className={classnames('fas fa-thumbs-up', {
                    'text-info': this.findUserLike(post.likes)
                  })}></i>
                  <span className="badge badge-light">{post.likes.length}</span>
                </button>
                <button onClick={this.onUnlikeClick} type="button" className="btn btn-light mr-1">
                  <i className="text-secondary fas fa-thumbs-down"></i>
                </button>
                <Link to={`/post/${post._id}`} className="btn btn-info mr-1">
                  Comments
              </Link>
                {post.user === auth.user.id ? (
                  <button onClick={this.onDeleteClick} className="btn btn-danger mr-1" type="button">
                    <i className="fas fa-times"></i>
                  </button>
                ) : null}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    )
  }
}

PostItem.defaultProps = {
  showActions: true
}

PostItem.propTypes = {
  deletePost: PropTypes.func.isRequired,
  addLike: PropTypes.func.isRequired,
  removeLike: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  auth: state.auth,
})

export default connect(mapStateToProps, { deletePost, addLike, removeLike })(PostItem);