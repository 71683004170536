import React, { Component } from 'react'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../common/Spinner';
import { getProfiles } from '../../actions/profilesActions';
import ProfileItem from './ProfileItem';

class Profiles extends Component {

 componentDidMount() {
  this.props.getProfiles();
 }

 render() {

  const { profiles } = this.props.profiles;
  const { profilesLoading } = this.props.profiles;
  let profileItems;

  if (profiles === null || profilesLoading) {
   profileItems = <Spinner></Spinner>
  } else {
   if (profiles.length > 0) {
    profileItems = profiles.map(profile => (
     <ProfileItem key={profile._id} profile={profile}></ProfileItem>
    ));
   } else {
    profileItems = <h4>No profiles found..</h4>
   }
  }

  return (
   <div className="profiles">
    <div className="container">
     <div className="row">
      <div className="col-md-12">
       <h1 className="display-4 text-center">Developer Profiles</h1>
       <p className="lead text-center">Browse and connect with developers</p>
       {profileItems}
      </div>
     </div>
    </div>
   </div>
  )
 }
}

Profiles.propTypes = {
 getProfiles: PropTypes.func.isRequired,
 profiles: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
 profiles: state.profiles
});

export default connect(mapStateToProps, { getProfiles })(Profiles);